














import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Checkbox from "@/components/inputs/Checkbox/index.vue";

@Component({
  name: "DialogWrapper",
  components: {
    Checkbox,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Emit()
  input(value: boolean) {
    return value;
  }
}
