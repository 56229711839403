





































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
  name: "DateTimePicker",
  components: {
    ValidationProvider,
    DatePicker,
  },
})
export default class extends Vue {
  @Prop({ required: false })
  public readonly value?: Date;

  @Prop({ required: false, default: "" })
  public readonly format?: string;

  @Prop({ required: false, default: "" })
  public readonly type?: string;

  @Prop({ required: false, default: "" })
  public readonly rules?: string | Record<string, any>;

  @Prop({ required: false, default: "" })
  public readonly name?: string;

  @Prop({ required: false, default: "" })
  public readonly vid?: string;

  @Prop({ required: false, default: undefined })
  public readonly disabledDate!: ((date: Date) => boolean) | undefined;

  @Prop({ required: false, default: undefined })
  public readonly disabledTime!: ((date: Date) => boolean) | undefined;

  @Emit()
  input(value: Date) {
    return value;
  }
}
