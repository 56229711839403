import { render, staticRenderFns } from "./OriginalImgDialog.vue?vue&type=template&id=54f5e65f&scoped=true&"
import script from "./OriginalImgDialog.vue?vue&type=script&lang=ts&"
export * from "./OriginalImgDialog.vue?vue&type=script&lang=ts&"
import style0 from "./OriginalImgDialog.vue?vue&type=style&index=0&id=54f5e65f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f5e65f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VDialog,VImg})
