















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "Checkbox",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: string;

  @Prop({ required: false, default: "" })
  public readonly label!: string;

  @Prop({ required: false, default: false })
  public readonly hideDetails!: string;

  @Prop({ required: false, default: false })
  public readonly dense!: string;

  @Emit()
  input(value: boolean | null) {
    return value ? true : false;
  }
}
