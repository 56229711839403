





































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "PageContainer",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: "" })
  public readonly parallaxSrc!: string;

  get showBackButton() {
    console.log("referer:", document.referrer);
    return (
      document.referrer.includes("recruit.com") ||
      !this.$router.currentRoute.meta?.firstEnter
    );
  }
}
