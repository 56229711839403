import request from "@/utils/request";
import { MediaModel } from "@/models/common";
import { UploadMediaModel } from "@/models/requests/UploadMedia";

class UploadMediaService {
  async upload(model: UploadMediaModel): Promise<MediaModel> {
    const formData = new FormData();
    formData.append("mediaFile", model.mediaFile);
    formData.append("title", model.title);
    formData.append("isMessage", model.isMessage.toString());

    if (model.description) {
      formData.append("description", model.description);
    }

    return await request.post("/public/UploadMedia/upload", formData);
  }
}

export default new UploadMediaService();
