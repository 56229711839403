import request from "@/utils/request";
import { MediaModel } from "@/models/common";
import { ActionTypes } from "@/models/requests/company/ActionsModels";

class CompanyService {
  async getMedia() {
    return await request.get<MediaModel[]>("/public/company/getMedia");
  }

  async callToActionFavorites({ companyId, isChecked }: ActionTypes) {
    return await request.post("/public/company/favorite", {
      companyId,
      isChecked,
    });
  }

  async callToActionHide({ companyId, isChecked }: ActionTypes) {
    return await request.post("/public/company/hide", {
      companyId,
      isChecked,
    });
  }

  async report(companyId: string) {
    return await request.post("/public/company/report", { companyId });
  }

  async sendMessage(
    recipientId: string,
    subject: string,
    textMessage: string,
    attachId: string | undefined = undefined
  ) {
    return await request.post("/public/company/sendMessage", {
      recipientId,
      subject,
      textMessage,
      attachId,
    });
  }
}

export default new CompanyService();
