import request from "@/utils/request";

class PageService {
  async getProfile<T>(url: string) {
    return await request.get<T>("/public/page/profile", {
      params: { url },
    });
  }
}

export default new PageService();
