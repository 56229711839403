var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'base-video',
    {
      paused: !_vm.isPlaying,
    } ]},[_c('video',{ref:"player",staticClass:"player",class:[
      {
        cover: _vm.cover,
      },
      'rounded-lg' ],attrs:{"src":_vm.src,"controls":_vm.controls,"width":"100%","height":"100%"},on:{"canplay":_vm.updatePaused,"playing":_vm.updatePaused,"pause":_vm.updatePaused}}),_c('v-btn',{staticClass:"play-button",attrs:{"small":"","fab":"","color":"#002e6d"},on:{"click":_vm.togglePlay}},[_c('v-icon',{attrs:{"large":"","color":"white"}},[(_vm.isPlaying)?[_vm._v("mdi-pause")]:[_vm._v("mdi-play")]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }