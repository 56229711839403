import request from "@/utils/request";
import {
  CandidateInterviewOpportunityModel,
  CandidateTemplate,
  CoworkerModel,
} from "@/models/Candidate";
import { MediaModel, TimeZoneModel } from "@/models/common";
import { CandidateCreateInterviewDTO } from "@/models/requests/Candidate/CandidateCreateInterviewModel";

class CandidateService {
  async getCoworkers() {
    return await request.get<CoworkerModel[]>("/public/candidate/getCoworkers");
  }

  async getTemplates() {
    return await request.get<CandidateTemplate[]>(
      "/public/candidate/getTemplates"
    );
  }

  async getOpportunities() {
    return await request.get<CandidateInterviewOpportunityModel[]>(
      "/public/candidate/getOpportunities"
    );
  }

  async getTimeZones() {
    return await request.get<TimeZoneModel[]>("/public/candidate/getTimeZones");
  }

  async getMedia() {
    return await request.get<MediaModel[]>("/public/candidate/getMedia");
  }

  async createInterview(model: CandidateCreateInterviewDTO) {
    return await request.post(`/public/candidate/interview`, {
      ...model,
    });
  }

  async invite(candidateId: string, template: number) {
    return await request.post(`/public/candidate/invite`, {
      candidateId,
      template,
    });
  }

  async passCandidate(candidateId: string, jobsId: string[]) {
    return await request.post(`/public/candidate/pass`, {
      candidateId,
      jobsId,
    });
  }

  async save(candidateId: string, jobsId: string[]) {
    return await request.post(`/public/candidate/save`, {
      candidateId,
      jobsId,
    });
  }

  async report(reportedUserId: string) {
    return await request.post("/public/candidate/report", { reportedUserId });
  }

  async sendMessage(
    recipientId: string,
    subject: string,
    textMessage: string,
    attachId: string | undefined = undefined
  ) {
    return await request.post("/public/candidate/sendMessage", {
      recipientId,
      subject,
      textMessage,
      attachId,
    });
  }
}

export default new CandidateService();
