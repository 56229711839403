












































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "OpportunityCard",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly title!: string;

  @Prop({ required: true, default: "" })
  public readonly salary!: string;

  @Prop({ required: true, default: "" })
  public readonly link!: string;

  @Prop({ required: false, default: "" })
  public readonly location!: string;

  @Prop({ required: false, default: "" })
  public readonly image!: string;

  private isActive = false;

  get avatar() {
    return this.image
      ? this.image
      : require("@/assets/no-avatar-job-opportunity.svg");
  }
}
