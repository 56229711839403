

















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "ChipsList",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: unknown[];

  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Emit("chips-clicked")
  onChipsClicked(item: unknown) {
    return item;
  }
}
