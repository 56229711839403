










































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import BaseVideo from "@/components/common/BaseVideo.vue";

@Component({
  name: "VideoCard",
  components: { BaseVideo },
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly src!: string;

  @Prop({ required: true, default: "" })
  public readonly title!: string;

  $refs!: {
    player: HTMLVideoElement;
  };

  videoDialogShown = false;

  isPlaying = false;

  get isMobileVariant(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  toggleVideoDialog(): void {
    this.videoDialogShown = !this.videoDialogShown;
  }

  setIsPlaying(value: boolean): void {
    this.$nextTick(() => {
      if (value) {
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }

      this.isPlaying = value;
    });
  }

  @Watch("videoDialogShown")
  onVideoDialogShown(newValue: boolean): void {
    this.setIsPlaying(newValue);
  }
}
