import { ALLOW_MEDIA_EXTENSIONS } from "@/constants/AllowMediaExtensions";

export default function getFileIcon(extension: string) {
  if (
    extension.length > 0 &&
    ALLOW_MEDIA_EXTENSIONS.includes(extension.toLocaleLowerCase().substring(1))
  ) {
    return require(`@/assets/icons/file-types/video.svg`);
  }

  let icon = "";
  switch (extension && extension.toLowerCase()) {
    case ".pdf":
      icon = "pdf.svg";
      break;
    case ".doc":
    case ".docx":
      icon = "doc.svg";
      break;
    case ".pptx":
      icon = "ppt.svg";
      break;
    default:
      icon = "unknown.svg";
      break;
  }

  return require(`@/assets/icons/file-types/${icon}`);
}
