























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaList from "./MediaList.vue";

@Component({
  name: "MediaSectionList",
  components: {
    MediaList,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: unknown[];

  @Prop({ required: false, default: 2 })
  public readonly previewCount!: number;

  private tabsModel = 0;

  get tabs() {
    const tabs = [];
    const videos = this.items.filter((x: any) => x.type === 3 || x.type === 5);
    const images = this.items.filter((x: any) => x.type === 0);
    const files = this.items.filter((x: any) => x.type === 1 || x.type === 2);

    videos.length > 0 && tabs.push({ title: "Videos", items: videos });
    images.length > 0 && tabs.push({ title: "Images", items: images });
    files.length > 0 && tabs.push({ title: "Files", items: files });

    return tabs;
  }
}
