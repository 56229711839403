import { render, staticRenderFns } from "./ApplyCardWrapper.vue?vue&type=template&id=09a6d5f4&scoped=true&"
import script from "./ApplyCardWrapper.vue?vue&type=script&lang=ts&"
export * from "./ApplyCardWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./ApplyCardWrapper.vue?vue&type=style&index=0&id=09a6d5f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a6d5f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
