
import { Component, Prop, Vue } from "vue-property-decorator";

type IPlayEvent = Event & { target: { paused: boolean } };

@Component({
  name: "BaseVideo",
})
export default class BaseVideo extends Vue {
  @Prop({ required: true, type: String })
  public readonly src!: string;

  @Prop({ required: false, type: Boolean, default: false })
  public readonly cover!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  public readonly isPreview!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  public readonly controls!: boolean;

  $refs!: {
    player: HTMLVideoElement;
  };

  isPlaying = false;

  public play(): void {
    if (this.isPreview) {
      return;
    }

    this.$refs.player.play();

    this.isPlaying = true;
  }

  public pause(): void {
    if (this.isPreview) {
      return;
    }

    this.$refs.player.pause();

    this.isPlaying = false;
  }

  togglePlay(): void {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  updatePaused(event: IPlayEvent) {
    if (this.isPreview) {
      return;
    }

    this.isPlaying = !event.target?.paused;
  }
}
