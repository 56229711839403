












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OriginalImgDialog from "./dialogs/OriginalImgDialog.vue";

@Component({
  name: "ImgCard",
  components: {
    OriginalImgDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly src!: string;

  @Prop({ required: true, default: "" })
  public readonly previewSrc!: string;

  private showOriginalImageDialog = false;
}
