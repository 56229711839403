



























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { OpportunityListItemModel } from "@/models/common";
import GridList from "@/components/lists/GridList/index.vue";
import OpportunityCard from "./OpportunityCard.vue";

@Component({
  name: "OpportunitiesList",
  components: {
    GridList,
    OpportunityCard,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: OpportunityListItemModel[];

  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly initLoading!: boolean;

  @Prop({ required: false, default: true })
  public readonly showMore!: boolean;

  @Emit()
  more() {}
}
