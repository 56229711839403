


























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "GridList",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly initLoading!: boolean;

  @Prop({ required: false, default: true })
  public readonly showMore!: boolean;

  @Emit()
  more() {}
}
