import request from "@/utils/request";
import {
  VacancyApplyModel,
  VacancyRemindToApplyAnonModel,
} from "@/models/requests/Vacancy";

class VacancyService {
  async jobPosting(vacancyId: string) {
    return await request.get<string>(`/public/vacancy/jobPosting`, {
      params: { vacancyId },
    });
  }

  async apply(vacancyId: string) {
    return await request.post(`/public/vacancy/apply`, {
      vacancyId,
    });
  }

  async anonymousApply(data: VacancyApplyModel) {
    const formData = new FormData();
    formData.append("vacancyId", data.vacancyId);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("email", data.email);
    if (data.file) {
      formData.append("file", data.file);
    }
    return await request.post(`/public/vacancy/anonymousApply`, formData);
  }

  async save(vacancyId: string) {
    return await request.post("/public/vacancy/save", { vacancyId });
  }

  async share(vacancyId: string, email: string, text: string) {
    return await request.post("/public/vacancy/share", {
      vacancyId,
      email,
      text,
    });
  }

  async remindToApply(vacancyId: string, remindDateTime: Date) {
    return await request.post("/public/vacancy/remindToApply", {
      vacancyId,
      remindDateTime,
    });
  }

  async remindToApplyAnon(data: VacancyRemindToApplyAnonModel) {
    return await request.post("/public/vacancy/remindToApplyAnonymous", data);
  }
}

export default new VacancyService();
