import { PaginationResponseModel } from "@/models/common";
import { SimilarOpportunitiesRequestModel } from "@/models/requests/Opportunities";
import { VacancyModel } from "@/models/Vacancy";
import request from "@/utils/request";

class OpportunityService {
  async similar(model: SimilarOpportunitiesRequestModel) {
    return await request.get<PaginationResponseModel<VacancyModel>>(
      "/search/opportunities/similar",
      {
        params: { ...model },
      }
    );
  }
}

export default new OpportunityService();
