import { SimilarCandidateModel } from "@/models/Candidate";
import { PaginationResponseModel } from "@/models/common";
import request from "@/utils/request";

class CandidateService {
  async similar(candidateId: string, page = 1, pageSize = 4) {
    return await request.get<PaginationResponseModel<SimilarCandidateModel>>(
      "/search/candidates/similar",
      {
        params: { candidateId, page, pageSize },
      }
    );
  }
}

export default new CandidateService();
