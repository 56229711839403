












import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  name: "OriginalImgDialog",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: false })
  public readonly show!: boolean;

  @Prop({ required: true, default: "" })
  public readonly src!: string;

  @Emit("update:show")
  changeVisibility(show: boolean) {
    return show;
  }
}
