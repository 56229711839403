






































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { facade } from "vue-input-facade";
import videojs from "video.js";
import KeyboardEvent = videojs.KeyboardEvent;

@Component({
  name: "TextField",
  components: {
    ValidationProvider,
  },
  directives: { facade },
})
export default class extends Vue {
  @Prop({ required: false })
  public readonly value?: string | number;

  @Prop({ required: false })
  public readonly name?: string;

  @Prop({ required: false })
  public readonly hint?: string;

  @Prop({ required: false })
  public readonly classes?: string;

  @Prop({ required: false })
  public readonly rules?: string;

  @Prop({ required: false })
  public readonly vid?: string;

  @Prop({ required: false })
  public readonly appendIcon?: string;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  public readonly readonly!: boolean;

  @Prop({ required: false, default: false })
  public readonly outlined!: boolean;

  @Prop({ required: false, default: false })
  public readonly dense!: boolean;

  @Prop({ required: false, default: false })
  public readonly hideDetails!: boolean;

  @Prop({ required: false, default: () => [] })
  public readonly errorMessages!: unknown[];

  @Prop({ required: false, default: "text" })
  public readonly type!: "text" | "number" | "password";

  @Prop({ required: false, type: String, default: null })
  public readonly mask!: string | null;

  @Emit("click:append")
  clickAppend(event: MouseEvent) {
    return event;
  }

  @Emit()
  input(value: string) {
    return this.type === "number" ? Number(value) : value;
  }

  @Emit()
  keypress(event: KeyboardEvent) {
    return event;
  }

  @Emit()
  keydown(event: KeyboardEvent) {
    return event;
  }

  @Emit()
  keyup(event: KeyboardEvent) {
    return event;
  }

  @Emit()
  mouseout(event: MouseEvent) {
    return event;
  }
}
