














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import getFileIcon from "@/utils/getFileIcon";

@Component({
  name: "FileCard",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "" })
  public readonly title!: string;

  @Prop({ required: true, default: "" })
  public readonly src!: string;

  @Prop({ required: false, default: "" })
  public readonly extension!: string;

  private getFileIcon = getFileIcon;

  downloadWithRealName() {
    // Need to download file with real name, attribute download is not working with azure storage
    // https://stackoverflow.com/a/33910461
    window.URL = window.URL || window.webkitURL;

    const xhr = new XMLHttpRequest();
    const a = document.createElement("a");
    let file: Blob;

    xhr.open("GET", this.src, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      file = new Blob([xhr.response], { type: "application/octet-stream" });
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = `${this.title}${this.extension}`; // Set to whatever file name you want
      // Now just click the link you created
      // Note that you may have to append the a element to the body somewhere
      // for this to work in Firefox
      a.click();
      window.URL.revokeObjectURL(url);
    };
    xhr.send();
  }
}
